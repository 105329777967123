import bootstrap from './bootstrap.png';
import creativeAgency from './creativeAgency.png';
import jogaimasaz from './jogaimasaz.png';
import kgp from './kgp.png';
import kodilla from './kodillaFurnitureShop.png';
import florist from './myFirstWebsite.png';
import rainbowl from './rainbowl-order.png';
import game from './rockpaperscissors.png';
import ameriqube from './ameriqube.png';
import smartqube from './smartqubeCut.png';
import HeadHunter from './HeadHunter.png';

export const gitProjects = {
  rainbowl,
  smartqube,
  ameriqube,
  jogaimasaz,
  HeadHunter,
  kgp,
  game,
  kodilla,
  florist,
  creativeAgency,
  bootstrap,
};
